<template>
  <VCard>
    <VToolbar flat>
      <VToolbarTitle>
        Матрица пользователя
      </VToolbarTitle>

      <VSpacer />
    </VToolbar>

    <VDivider />

    <VCardText>
      <VTreeview
        open-all
        open-on-click
        item-children="skills"
        :items="matrix.matrix"
      >
        <template #append="{ item }">
          <VChipGroup>
            <VChip v-if="item.level !== undefined">
              level: {{ item.level }}
            </VChip>

            <VChip>
              uuid: {{ item.id }}
            </VChip>
          </VChipGroup>
        </template>
      </VTreeview>

      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code
              class="elevation-0 d-block"
              style="white-space: pre"
              v-text="matrix"
            />
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
export default {
  name: 'UserMatrixCard',

  inject: ['Names'],

  props: {
    matrix: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
