<template>
  <TTView>
    <VRow>
      <VCol>
        <UserMatrixCard :matrix="matrix" />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <PositionCard
          :position="matrix.position"
          :companies="companies"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import UserMatrixCard from '../../components/competency-matrix/positions/UserMatrixCard.vue';
import PositionCard from '../../components/competency-matrix/positions/PositionCard.vue';

export default {
  name: 'PositionsUserMatrixView',

  components: {
    UserMatrixCard,
    PositionCard,
  },

  inject: ['Names'],

  data() {
    return {
      matrix: { matrix: [] },
      companies: [],
      loading: false,
    };
  },

  computed: {
    userId() {
      return this.$route.params.userId;
    },
    companyId() {
      return this.$route.params.companyId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { userId, companyId } = this;
        const data = { userId, companyId };

        const [companiesResponse, matrixResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.CompetencyMatrix.PositionsUserMatrix(data),
        ]);

        this.companies = companiesResponse.companies || [];
        this.matrix = matrixResponse || {};
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
